<template>
  <section>
    <v-row>
      <v-col md="12" v-if="allMLdata.results == undefined">
       <Loading/>
      </v-col>

      <v-col md="12" v-if="allMLdata.results">
        <Table
          :alltabledata="allMLdata.results"
          :actionFuction="fetchMlData"
          tableFrom="mltableDetails"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import Table from "@/components/dashboard/Table";
import Loading from "@/components/dashboard/Loading";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MLData",
  components: { Table,Loading },
  computed: {
    ...mapGetters(["allSpecificMenus", "allMLdata"]),
  },
  datra() {
    return {
      skip: 1,
      limit: 100,
    };
  },
  methods: {
    ...mapActions(["fetchMlData"]),
  },
  created() {
    this.fetchMlData({
      limit: 100,
      skip: 1,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;
  li {
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
      // color: $white;
    }
  }
}
</style>
