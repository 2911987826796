<template>
  <div>
    <section v-if="tableFrom == 'propertyDetails'">
      <v-col md="12" v-if="alltabledata != undefined">
        <h3 class="table-heading">Property Detail Data</h3>
        <v-simple-table class="tableborder">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Title</th>
              <th>Price</th>
              <th>House Type</th>
              <th>House Sub Type</th>
              <th>New Build</th>
              <th>Retirement Property</th>

              <th>Auction</th>
              <th>Is Listing Live</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody v-if="alltabledata.list != undefined">
            <tr v-for="(data, index) in alltabledata.list" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ data.propertyOf }}</td>
              <td>{{ data.price.amount }}</td>
              <td>{{ data.housetype }}</td>
              <td>{{ data.housesubtype }}</td>
              <td>
                <span v-if="data.newbuild" class="positiveColor">Yes</span
                ><span v-if="!data.newbuild" class="negativeColor">No</span>
              </td>
              <td>
                <span v-if="data.retirementproperty" class="positiveColor"
                  >Yes</span
                ><span v-if="!data.retirementproperty" class="negativeColor"
                  >No</span
                >
              </td>

              <td>
                <span v-if="data.auction" class="positiveColor">Yes</span
                ><span v-if="!data.auction" class="negativeColor">No</span>
              </td>
              <td>
                <span v-if="data.islistinglive" class="positiveColor">Yes</span
                ><span v-if="!data.islistinglive" class="negativeColor"
                  >No</span
                >
              </td>
              <td>
                <router-link
                  :to="'/dashboard/propertydetails/' + data._id"
                  class="linkbtn"
                  ><v-icon>mdi-eye</v-icon></router-link
                >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <ul class="pagination-button">
          <li>
            Page : {{ (parseInt(alltabledata.pagination.next) - 1) * 100 }} -
            {{ alltabledata.pagination.total }}
          </li>
          <li>
            <div>
              <v-btn
                v-if="alltabledata.pagination.previous"
                @click="prevPage(alltabledata.pagination.previous)"
                >Prev</v-btn
              >

              <v-btn
                v-if="alltabledata.pagination.next"
                @click="nextPage(alltabledata.pagination.next)"
                >Next</v-btn
              >
            </div>
          </li>
        </ul>
      </v-col>
    </section>
    <section v-if="tableFrom == 'propertyZoopla'">
      <v-col md="12" v-if="alltabledata != undefined">
        <h3 class="table-heading">Property Detail Data</h3>
        <v-simple-table class="tableborder">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Title</th>
              <th>Price</th>
              <th>House Type</th>
              <th>House Sub Type</th>
              <th>New Build</th>
              <th>Retirement Property</th>

              <th>Auction</th>
              <th>Is Listing Live</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody v-if="alltabledata.list != undefined">
            <tr v-for="(data, index) in alltabledata.list" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ data.address }}</td>
              <td>{{ data.price }}</td>
              <td ><div v-if="data.tags[0] && data.tags[0].content">{{ data.tags[0].content }}</div><div v-if="data.tags[0]==undefined">Not Specified</div></td>
              <td>{{ data.housesubtype }}</td>
              <td>
                <span v-if="data.newbuild" class="positiveColor">Yes</span
                ><span v-if="!data.newbuild" class="negativeColor">No</span>
              </td>
              <td>
                <span v-if="data.retirementproperty" class="positiveColor"
                  >Yes</span
                ><span v-if="!data.retirementproperty" class="negativeColor"
                  >No</span
                >
              </td>

              <td>
                <span v-if="data.auction" class="positiveColor">Yes</span
                ><span v-if="!data.auction" class="negativeColor">No</span>
              </td>
              <td>
                <span class="positiveColor">Yes</span
                >
              </td>
              <td>
                <router-link
                  :to="'/dashboard/propertydetails/' + data._id"
                  class="linkbtn"
                  ><v-icon>mdi-eye</v-icon></router-link
                >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <ul class="pagination-button">
          <li>
            Page : {{ (parseInt(alltabledata.pagination.next) - 1) * 100 }} -
            {{ alltabledata.pagination.total }}
          </li>
          <li>
            <div>
              <v-btn
                v-if="alltabledata.pagination.previous"
                @click="prevPage(alltabledata.pagination.previous)"
                >Prev</v-btn
              >

              <v-btn
                v-if="alltabledata.pagination.next"
                @click="nextPage(alltabledata.pagination.next)"
                >Next</v-btn
              >
            </div>
          </li>
        </ul>
      </v-col>
    </section>
    <section v-if="tableFrom == 'paginationDetails'">
      <v-col md="12" v-if="alltabledata != undefined">
        <h3 class="table-heading">Search Url Table</h3>
        <v-simple-table class="tableborder">
          <thead>
            <tr>
              <th>Title</th>
              <th>Link</th>
              <th>Type</th>
              <th>Search For</th>
            </tr>
          </thead>
          <tbody v-if="alltabledata.list != undefined">
            <tr v-for="(data, index) in alltabledata.list" :key="index">
              <td>{{ data.title }}</td>
              <td>
                <a
                  :href="data.link"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ data.link }}</a
                >
              </td>
              <td>{{ data.type }}</td>
              <td>{{ data.searchFor }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <ul class="pagination-button">
          <li>
            Page : {{ (parseInt(alltabledata.pagination.next) - 1) * 100 }} -
            {{ alltabledata.pagination.total }}
          </li>
          <li>
            <div>
              <v-btn
                v-if="alltabledata.pagination.previous"
                @click="prevPage(alltabledata.pagination.previous)"
                >Prev</v-btn
              >

              <v-btn
                v-if="alltabledata.pagination.next"
                @click="nextPage(alltabledata.pagination.next)"
                >Next</v-btn
              >
            </div>
          </li>
        </ul>
      </v-col>
    </section>
    <section v-if="tableFrom == 'searchcodesData'">
      <v-col md="12" v-if="alltabledata != undefined">
        <h3 class="table-heading">Search Url Table</h3>
        <v-simple-table class="tableborder">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Place</th>
              <th>Search Codes</th>
            </tr>
          </thead>
          <tbody v-if="alltabledata.list != undefined">
            <tr v-for="(data, index) in alltabledata.list" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ data.place }}</td>
              <td>{{ data.searchcode }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <ul class="pagination-button">
          <li>
            Page : {{ (parseInt(alltabledata.pagination.next) - 1) * 100 }} -
            {{ alltabledata.pagination.total }}
          </li>
          <li>
            <div>
              <v-btn
                v-if="alltabledata.pagination.previous"
                @click="prevPage(alltabledata.pagination.previous)"
                >Prev</v-btn
              >

              <v-btn
                v-if="alltabledata.pagination.next"
                @click="nextPage(alltabledata.pagination.next)"
                >Next</v-btn
              >
            </div>
          </li>
        </ul>
      </v-col>
    </section>
    <section v-if="tableFrom == 'paginationdata'">
      <v-col md="12" v-if="alltabledata != undefined">
        <h3 class="table-heading">Paginated Property</h3>
        <v-simple-table class="tableborder">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Property Title</th>
              <th>Property Of</th>
              <th>Website</th>
              <th>Is Single Page Scraped</th>
            </tr>
          </thead>
          <tbody v-if="alltabledata.list != undefined">
            <tr v-for="(data, index) in alltabledata.list" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ data.propertyTitle }}</td>
              <td>{{ data.propertyOf }}</td>
              <td>{{ data.website }}</td>
              <td>{{ data.isps ? "Yes" : "No" }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <ul class="pagination-button">
          <li>
            Page : {{ (parseInt(alltabledata.pagination.next) - 1) * 100 }} -
            {{ alltabledata.pagination.total }}
          </li>
          <li>
            <div>
              <v-btn
                v-if="alltabledata.pagination.previous"
                @click="prevPage(alltabledata.pagination.previous)"
                >Prev</v-btn
              >

              <v-btn
                v-if="alltabledata.pagination.next"
                @click="nextPage(alltabledata.pagination.next)"
                >Next</v-btn
              >
            </div>
          </li>
        </ul>
      </v-col>
    </section>
    <section v-if="tableFrom == 'processmldata'">
      <v-col md="12" v-if="alltabledata != undefined">
        <h3 class="table-heading">Paginated Property</h3>
        <v-simple-table class="tableborder">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Property Address</th>
              <th>Bedrooms</th>
              <th>Bathrooms</th>
              <th>Location</th>
              <th>Property Types</th>
              <th>Price</th>
              <th>New Home</th>
              <th>Share Ownership</th>
              <th>Auction</th>
              <th>Need Refurbishment</th>
              <th>Classification Done</th>
            </tr>
          </thead>
          <tbody v-if="alltabledata.list != undefined">
            <tr v-for="(data, index) in alltabledata.list" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ data.displayAddress }}</td>
              <td>{{ data.bedrooms }}</td>
              <td>{{ data.bathrooms }}</td>
              <td>
                Long : {{ data.location.longitude }} - Lat:
                {{ data.location.latitude }}
              </td>
              <td>{{ data.propertySubType }}</td>
              <td>{{ data.price.amount }}</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>{{ data.isps ? "Yes" : "No" }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <ul class="pagination-button">
          <li>
            Page : {{ (parseInt(alltabledata.pagination.next) - 1) * 1000 }} -
            {{ alltabledata.pagination.total }}
          </li>
          <li>
            <div>
              <v-btn
                v-if="alltabledata.pagination.previous"
                @click="prevPage(alltabledata.pagination.previous)"
                >Prev</v-btn
              >

              <v-btn
                v-if="alltabledata.pagination.next"
                @click="nextPage(alltabledata.pagination.next)"
                >Next</v-btn
              >
            </div>
          </li>
        </ul>
      </v-col>
    </section>
    <section v-if="tableFrom == 'mltableDetails'">
      <v-col md="12" v-if="alltabledata != undefined">
        <h3 class="table-heading">ML Property</h3>
        <v-simple-table class="tableborder">
          <thead>
            <tr>
              <th>S/N</th>
              <th>zid</th>
              <th>Longitude</th>
              <th>Latitude</th>
              <th>LLPoint</th>
              <th>StreetName</th>
              <th>DoorNumber</th>
              <th>PostCode</th>
              <th>Beds</th>
              <th>Baths</th>
              <th>Receps</th>
              <th>IncomeDeprivation</th>
              <th>Employmentheprivation</th>
              <th>EducationSkillsAndTraining</th>
              <th>idaopi</th>
              <th>idaci</th>
              <th>HealthDeprivation</th>
              <th>LivingEnvironment</th>
              <th>BarriersToHousingAndServices</th>
              <th>Urbanness</th>
              <th>OldestSale</th>
              <th>OldestSaleDate</th>
              <th>OldestSaleDateTS</th>
              <th>OldestHPI</th>
              <th>MostRecentSale</th>
              <th>MostRecentSaleDate</th>
              <th>MostRecentSaleDateTS</th>
              <th>Tenure</th>
              <th>PropertyType</th>
              <th>HistoricHPI</th>
              <th>MostRecentHPI</th>
              <th>Oldesthiff</th>
              <th>MostRecentSaleDiff</th>
              <th>DifferenceInPrice_Percent</th>
              <th>DifferenceInDaysBetweenSales</th>
              <th>cacr</th>
              <th>IDifferenceInPrice_Percent</th>
              <th>IDifferenceInDaysBetweenSales</th>
              <th>icar</th>
              <th>total_floor_area</th>
              <th>extension_count</th>
              <th>number_habitable_rooms</th>
              <th>construction_age_band</th>
              <th>floor_level</th>
              <th>flat_top_storey</th>
              <th>flat_storey_count</th>
              <th>oa11</th>
              <th>PriceHigh</th>
              <th>PriceLow</th>
              <th>TestSuiteSample</th>
              <th>PriceAverage</th>
              <th>ISoldPrice</th>
            </tr>
          </thead>
          <tbody v-if="alltabledata.list != undefined">
            <tr v-for="(data, index) in alltabledata.list" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ data.zid }}</td>
              <td>{{ data.Longitude }}</td>
              <td>{{ data.Latitude }}</td>
              <td>{{ data.LLPoint }}</td>
              <td>{{ data.StreetName }}</td>
              <td>{{ data.DoorNumber }}</td>
              <td>{{ data.PostCode }}</td>
              <td>{{ data.Beds }}</td>
              <td>{{ data.Baths }}</td>
              <td>{{ data.Receps }}</td>
              <td>{{ data.IncomeDeprivation }}</td>
              <td>{{ data.EmploymentDeprivation }}</td>
              <td>{{ data.EducationSkillsAndTraining }}</td>
              <td>{{ data.idaopi }}</td>
              <td>{{ data.idaci }}</td>
              <td>{{ data.HealthDeprivation }}</td>
              <td>{{ data.LivingEnvironment }}</td>
              <td>{{ data.BarriersToHousingAndServices }}</td>
              <td>{{ data.Urbanness }}</td>
              <td>{{ data.OldestSale }}</td>
              <td>{{ data.OldestSaleDate }}</td>
              <td>{{ data.OldestSaleDateTS }}</td>
              <td>{{ data.OldestHPI }}</td>
              <td>{{ data.MostRecentSale }}</td>
              <td>{{ data.MostRecentSaleDate }}</td>
              <td>{{ data.MostRecentSaleDateTS }}</td>
              <td>{{ data.Tenure }}</td>
              <td>{{ data.PropertyType }}</td>
              <td>{{ data.HistoricHPI }}</td>
              <td>{{ data.MostRecentHPI }}</td>
              <td>{{ data.OldestDiff }}</td>
              <td>{{ data.MostRecentSaleDiff }}</td>
              <td>{{ data.DifferenceInPrice_Percent }}</td>
              <td>{{ data.DifferenceInDaysBetweenSales }}</td>
              <td>{{ data.cacr }}</td>
              <td>{{ data.IDifferenceInPrice_Percent }}</td>
              <td>{{ data.IDifferenceInDaysBetweenSales }}</td>
              <td>{{ data.icar }}</td>
              <td>{{ data.total_floor_area }}</td>
              <td>{{ data.extension_count }}</td>
              <td>{{ data.number_habitable_rooms }}</td>
              <td>{{ data.construction_age_band }}</td>
              <td>{{ data.floor_level }}</td>
              <td>{{ data.flat_top_storey }}</td>
              <td>{{ data.flat_storey_count }}</td>
              <td>{{ data.oa11 }}</td>
              <td>{{ data.PriceHigh }}</td>
              <td>{{ data.PriceLow }}</td>
              <td>{{ data.TestSuiteSample }}</td>
              <td>{{ data.PriceAverage }}</td>
              <td>{{ data.ISoldPrice }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <ul class="pagination-button">
          <li>
            Page : {{ (parseInt(alltabledata.pagination.next) - 1) * 100 }} -
            {{ alltabledata.pagination.total }}
          </li>
          <li>
            <div>
              <v-btn
                v-if="alltabledata.pagination.previous"
                @click="prevPage(alltabledata.pagination.previous)"
                >Prev</v-btn
              >

              <v-btn
                v-if="alltabledata.pagination.next"
                @click="nextPage(alltabledata.pagination.next)"
                >Next</v-btn
              >
            </div>
          </li>
        </ul>
      </v-col>
    </section>
    <section v-if="tableFrom == 'fasttableDetails'">
      <v-col md="12" v-if="alltabledata != undefined">
        <h3 class="table-heading">Fast Table Property</h3>
        <v-simple-table class="tableborder">
          <thead>
            <tr>
              <th>S/N</th>
              <th>identifier</th>
              <th>Is Listing Live</th>
              <th>PostCode</th>
              <th>Latitude</th>
              <th>Longitude</th>
              <th>HouseType</th>
              <th>Auction</th>
              <th>Shared Ownership</th>
            </tr>
          </thead>
          <tbody v-if="alltabledata.list != undefined">
            <tr v-for="(data, index) in alltabledata.list" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ data.identifier }}</td>
              <td>{{ data.IsListingLive }}</td>
              <td>{{ data.PostCode }}</td>
              <td>{{ data.Longitude }}</td>
              <td>{{ data.Latitude }}</td>
              <td>{{ data.HouseType }}</td>
              <td>{{ data.Auction }}</td>
              <td>{{ data.SharedOwnership }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <ul class="pagination-button">
          <li>
            Page : {{ (parseInt(alltabledata.pagination.next) - 1) * 100 }} -
            {{ alltabledata.pagination.total }}
          </li>
          <li>
            <div>
              <v-btn
                v-if="alltabledata.pagination.previous"
                @click="prevPage(alltabledata.pagination.previous)"
                >Prev</v-btn
              >

              <v-btn
                v-if="alltabledata.pagination.next"
                @click="nextPage(alltabledata.pagination.next)"
                >Next</v-btn
              >
            </div>
          </li>
        </ul>
      </v-col>
    </section>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: ["alltabledata", "actionFuction", "tableFrom", "hasfilter"],
  methods: {
    nextPage(nt) {
      if (this.hasfilter) {
        if (this.alltabledata.pagination.next != undefined) {
          this.actionFuction({ limit: 100, skip: nt, ...this.hasfilter });
        }
      } else {
        if (this.alltabledata.pagination.next != undefined) {
          this.actionFuction({ limit: 100, skip: nt });
        }
      }
    },
    prevPage(pt) {
      if (this.hasfilter) {
        if (this.alltabledata.pagination.previous != undefined) {
          this.actionFuction({ limit: 100, skip: pt, ...this.hasfilter });
        }
      } else {
        if (this.alltabledata.pagination.previous != undefined) {
          this.actionFuction({ limit: 100, skip: pt });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
.tableborder {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  align-items: center;
}
.pagination-button {
  border-top: 0;
  padding: 5px 10px;
}
.table-heading {
  border-bottom: 0;
}
.linkbtn {
  text-decoration: none;
}
.positiveColor {
  background: green;
  color: #fff;
  display: block;
  padding: 5px;
}
.negativeColor {
  background: red;
  color: #fff;
    display: block;
  padding: 5px;
}
</style>
